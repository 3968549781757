import { graphql, useStaticQuery } from 'gatsby';
import React, { FC } from 'react';
import Helmet from 'react-helmet';

import { DefaultSeoQuery, Product } from '../../graphql-types';
import favicon from '../../static/favicon.ico';

export type BlogPostSEOProps = {
  description: string;
  author: string;
  datePublished: string;
  dateModified: string;
};

export type ImageSEOProps = {
  src: string;
  width: number;
  height: number;
};

const getSchemaOrgJSONLD = ({
  siteMetadata,
  url,
  title,
  image,
  blogPost,
  product,
}: {
  siteMetadata: any;
  url: string;
  title: string;
  image?: ImageSEOProps;
  blogPost?: BlogPostSEOProps;
  product?: Product;
}) => {
  const organization = {
    '@type': 'Organization',
    url: siteMetadata.siteUrl,
    name: 'OptiOffer',
    logo: {
      '@type': 'ImageObject',
      url: `${siteMetadata.siteUrl}/android-chrome-512x512.png`,
    },
    sameAs: [
      'https://twitter.com/OptiOffer',
      'https://www.facebook.com/OptiOffer',
      'https://www.youtube.com/channel/UCyJZcY5SnI8UJT993PFS57Q',
      'https://www.instagram.com/optioffer/',
      'https://www.linkedin.com/company/optioffer/',
      'https://github.com/OptiOffer',
    ],
    address: {
      '@type': 'PostalAddress',
      streetAddress: 'Piața Emanuil Gojdu, 47',
      addressRegion: 'Oradea',
      postalCode: '410067',
      addressCountry: 'Romania',
    },
  };

  const schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url,
      name: title,
      alternateName: siteMetadata.title,
    },
    {
      '@context': 'http://schema.org',
      ...organization,
    },
  ];

  if (blogPost) {
    return [
      ...schemaOrgJSONLD,
      {
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            item: {
              '@id': siteMetadata.siteUrl,
              name: 'OptiOffer',
              image: `${siteMetadata.siteUrl}/android-chrome-512x512.png`,
            },
          },
          {
            '@type': 'ListItem',
            position: 2,
            item: {
              '@id': `${siteMetadata.siteUrl}/blog`,
              name: 'Blog',
            },
          },
          {
            '@type': 'ListItem',
            position: 3,
            item: {
              '@id': url,
              name: title,
              image: image?.src,
            },
          },
        ],
      },
      {
        '@context': 'http://schema.org',
        '@type': 'BlogPosting',
        url,
        name: title,
        alternateName: siteMetadata.title,
        headline: title,
        image: {
          '@type': 'ImageObject',
          url: image?.src,
        },
        description: blogPost.description,
        author: {
          '@type': 'Person',
          name: blogPost.author,
          affiliation: organization,
          brand: organization,
          worksFor: organization,
        },
        publisher: organization,
        mainEntityOfPage: {
          '@type': 'WebSite',
          '@id': siteMetadata.siteUrl,
        },
        datePublished: blogPost.datePublished,
        dateModified: blogPost.dateModified,
      },
    ];
  }

  if (product) {
    return [
      ...schemaOrgJSONLD,
      {
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            item: {
              '@id': siteMetadata.siteUrl,
              name: 'OptiOffer',
              image: `${siteMetadata.siteUrl}/android-chrome-512x512.png`,
            },
          },
          {
            '@type': 'ListItem',
            position: 2,
            item: {
              '@id': `${siteMetadata.siteUrl}/products`,
              name: 'Produse',
            },
          },
          ...(product.categories.length
            ? [...product.categories[0].breadcrumbs, product.categories[0]].map(
                (cat, idx) => ({
                  '@type': 'ListItem',
                  position: 3 + idx,
                  item: {
                    '@id': `${siteMetadata.siteUrl}/${cat.path}`,
                    name: cat.name,
                  },
                })
              )
            : []),
          {
            '@type': 'ListItem',
            position:
              4 +
              (product.categories.length
                ? product.categories[0].breadcrumbs.length
                : 0),
            item: {
              '@id': url,
              name: title,
              image: image?.src,
            },
          },
        ],
      },
      {
        '@context': 'http://schema.org',
        '@type': 'Product',
        name: product.name,
        image: product.image?.childImageSharp
          ? product.image.childImageSharp.gatsbyImageData.images.sources.flatMap(
              ({ srcSet }) =>
                srcSet
                  .split(',\n')
                  .map(
                    (srcSetEntry) =>
                      `${siteMetadata.siteUrl}${srcSetEntry.split(' ')[0]}`
                  )
            )
          : [],
        description: product.description,
        sku: product.code,
        mpn: product.code,
        brand: ((prod) =>
          prod
            ? {
                '@type': 'Brand',
                name: prod.split('-')[0].trim(),
                description: prod,
              }
            : undefined)(
          product.specifications.find((it) => it.name === 'Producator')?.value
        ),
        offers: {
          '@type': 'AggregateOffer',
          offerCount: '2',
          lowPrice: product.averageMinPrice.value.toFixed(2),
          highPrice: product.price.value.toFixed(2),
          priceCurrency: product.price.currency,
        },
      },
    ];
  }

  return schemaOrgJSONLD;
};

type SEOProps = {
  title: string;
  description: string;
  url: string;
  lang?: string;
  image?: ImageSEOProps;
  blogPost?: BlogPostSEOProps;
  product?: Product;
  disableIndex?: boolean;
};

const SEO: FC<SEOProps> = ({
  title,
  description,
  url,
  image,
  blogPost,
  product,
  disableIndex,
  lang = 'en',
}) => {
  const {
    site: { siteMetadata },
  } = useStaticQuery<DefaultSeoQuery>(graphql`
    query DefaultSEO {
      site {
        siteMetadata {
          title
          siteUrl
          disableIndex
          twitterLink
        }
      }
    }
  `);

  const pageTitle = title || siteMetadata.title;
  const fullUrl = `${siteMetadata.siteUrl}/${
    url ? (url.indexOf('/') === 0 ? url.substring(1) : url) : ''
  }`;
  const imageUrl = `${siteMetadata.siteUrl}${
    image ? image.src : '/oo_logo_wide.png'
  }`;

  const schemaOrgJSONLD = getSchemaOrgJSONLD({
    siteMetadata,
    url: fullUrl,
    title: pageTitle,
    blogPost,
    product,
    image,
  });

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={pageTitle}
      titleTemplate={`%s - ${siteMetadata.title}`}
      link={[
        {
          rel: 'shortcut icon',
          type: 'image/ico',
          href: favicon,
        },
        ...(product ? [{ rel: 'canonical', href: fullUrl }] : []),
      ]}
    >
      {(siteMetadata.disableIndex || disableIndex) && (
        <meta name="robots" content="noindex" />
      )}

      {/* General tags */}
      <meta charSet="UTF-8" />
      <meta name="description" content={description} />
      <meta name="image" content={imageUrl} />

      {/* Schema.org tags */}
      {title && (
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgJSONLD)}
        </script>
      )}

      {/* OpenGraph tags */}
      <meta property="og:url" content={fullUrl} />
      {blogPost ? (
        <meta property="og:type" content="article" />
      ) : (
        <meta property="og:type" content="website" />
      )}
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      <meta
        property="og:image:width"
        content={image ? `${image.width}` : '1200'}
      />
      <meta
        property="og:image:height"
        content={image ? `${image.height}` : '628'}
      />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={siteMetadata.twitterLink} />
      <meta name="twitter:creator" content={siteMetadata.twitterLink} />
      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageUrl} />
    </Helmet>
  );
};

export default SEO;
